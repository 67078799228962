import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar } = this.props;

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
          <div className="kt-header__topbar-user">
            {showHi && (<span className="kt-header__topbar-welcome kt-hidden-mobile">Olá,</span>)}

            {showHi && (<span className="kt-header__topbar-username kt-hidden-mobile">{user.usu_nome || user.adm_nome}</span>)}

            {showAvatar &&
              <div style={{
                width: '34px', height: '34px', backgroundImage: `url(${user.foto ? user.foto : '/images/no-profile.png'})`,
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', borderRadius: '50%'
              }} />
            }
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-5.jpg")})` }}>

            {showAvatar &&
              <div className="kt-user-card__avatar">
                <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                  <img alt="" src={user.foto ? user.foto : '/images/no-profile.png'} />
                </span>
              </div>
            }

            <div className="kt-user-card__name">{user.usu_nome || user.adm_nome}</div>
          </div>

          <div className="kt-notification">
            <div className="kt-notification__custom">
              <Link to="/logout" className="btn btn-primary btn-bold">Sair</Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(UserProfile);
