import { Constants } from '../../app/utils/Constants';

export default {
	header: {
		self: {},
		items: [
			{
				title: "Suporte",
				root: true,
				alignment: "left",
				link: Constants.whatsappLink,
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fa fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Anunciantes",
				root: true,
				icon: "fas fa-street-view",
				bullet: "dot",
				page: "anunciante"
			},
			{
				title: "Integração XML",
				root: true,
				icon: "fa fa-server",
				bullet: "dot",
				page: "integracao"
			},
			{
				title: "Notificações",
				root: true,
				icon: "fa fa-envelope",
				page: "notificacao",
				bullet: "dot",
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Anúncios por bairro",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Propostas enviadas",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
					{
						title: "Faturamento de assinatura",
						bullet: "dot",
						page: "relatorio/relatorio03"
					},
				]
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Grupos",
				root: true,
				icon: "fas fa-layer-group",
				page: "grupo",
				bullet: "dot"
			},
		]
	}
};
