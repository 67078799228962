import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GrupoPage = lazy(() =>
	import("./grupo/GrupoPage")
);

const NotificacaoPage = lazy(() =>
	import("./notificacao/NotificacaoPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const AnunciantePage = lazy(() =>
	import("./anunciante/AnunciantePage")
);

const IntegracaoPage = lazy(() =>
	import("./integracao/IntegracaoPage")
);

export default function HomePage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/grupo" component={GrupoPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/notificacao" component={NotificacaoPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/anunciante" component={AnunciantePage} />
				<Route path="/integracao" component={IntegracaoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
